import React from "react";
import { Link } from "gatsby";
import logo from "../img/evolve-strong-fitness-logo.png";
import facebook from "../img/social/facebook.svg";
import instagram from "../img/social/instagram.svg";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-white position-relative">
        <div className="content">
          <div className="container">
            <div className="columns footer-columns mx-0">
              <div className="column is-4 footer-column-left">
                <section className="footer-menu">
                  <ul className="footer-menu-list">
                    <li>
                      <Link to="/about" className="footer-menu-item">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/about/classes">
                        Classes
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/about/what-to-bring">
                        What to Bring
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/schedule">
                        Schedule
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/vault">
                        EVOLVE Vault
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/member-sign-in">
                        Member Sign In
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/sign-up">
                        Sign Up
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-narrow footer-column-center">
                <div className="content has-text-centered">
                  <Link to="/">
                    <PreviewCompatibleImage
                      imageInfo={{
                        image: logo,
                        alt: "EVOLVE Strong Fitness",
                        className: "footer-logo",
                      }}
                    />
                  </Link>
                </div>
              </div>
              <div className="column is-4 footer-column-right">
                <section className="footer-menu">
                  <ul className="footer-menu-list">
                    <li>
                      <a
                        title="facebook"
                        className="mr-4"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.facebook.com/evolvestrongfitness/"
                      >
                        <img
                          src={facebook}
                          alt="Facebook"
                          className="grow"
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      </a>
                      <a
                        title="instagram"
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.instagram.com/evolvestrongfitness/"
                      >
                        <img
                          src={instagram}
                          alt="Instagram"
                          className="grow"
                          style={{ width: "2rem", height: "2rem" }}
                        />
                      </a>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/shop">
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/privacy-policy">
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/terms-of-service">
                        Terms of Service
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/disclaimer">
                        Disclaimer
                      </Link>
                    </li>
                    <li>
                      <Link className="footer-menu-item" to="/cookie-policy">
                        Cookie Policy
                      </Link>
                    </li>
                    <li>
                      <a className="footer-menu-item" href="javascript:UC_UI.showSecondLayer();" id="usercentrics-psl">Privacy Settings</a>
                    </li>
                    <li>
                      <a
                        className="footer-menu-item"
                        target="_blank"
                        rel="noreferrer"
                        href="https://catherinepollock.com"
                      >
                        Website by Catherine Pollock
                      </a>
                    </li>
                  </ul>
                </section>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
