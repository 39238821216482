import React from "react";
import { Link } from "gatsby";
import logo from "../img/evolve-strong-fitness-logo.png";
import ChevronDown from "./icons/ChevronDown";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
      aboutActive: false,
      aboutActiveClass: "",
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  toggleAboutDropdown = () => {
    this.state.aboutActive
      ? this.deactivateAboutDropdown()
      : this.activateAboutDropdown();
  };

  deactivateAboutDropdown = () => {
    this.setState(
      {
        aboutActive: false,
      },
      // after state has been updated,
      () => {
        this.setState({
          aboutActiveClass: "",
        });
      }
    );
  };

  activateAboutDropdown = () => {
    this.setState(
      {
        aboutActive: true,
      },
      // after state has been updated,
      () => {
        this.setState({
          aboutActiveClass: "is-active",
        });
      }
    );
  };

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="navbar-brand position-relative">
            <Link to="/" className="navbar-item" aria-label="Home">
              <img src={logo} alt="EVOLVE Strong Fitness" />
            </Link>

            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
              onKeyDown={() => this.toggleHamburger()}
              role="button"
              tabIndex={0}
              aria-label="Toggle menu"
            >
              <span className="navbar-burger-line" />
              <span className="navbar-burger-line" />
              <span className="navbar-burger-line" />
            </div>
          </div>
          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <div className="navbar-start"></div>
            <div className="navbar-end has-text-centered">
              {/* eslint-disable-next-line */}
              <div
                className={`navbar-item has-dropdown is-arrowless ${this.state.aboutActiveClass}`}
                onMouseLeave={() =>
                  this.state.aboutActive ? this.deactivateAboutDropdown() : null
                }
                onKeyDown={() => this.toggleAboutDropdown()}
                onMouseEnter={() =>
                  this.state.aboutActive ? null : this.activateAboutDropdown()
                }
                role="button"
                tabIndex={0}
                aria-label="Toggle menu dropdown"
              >
                <Link className="navbar-link is-arrowless" to="/about">
                  About
                </Link>
                <div className="pr-2 py-2 is-hidden-touch align-self-center">
                  <ChevronDown />
                </div>
                <div className="navbar-dropdown" id="aboutMenuDropdown">
                  <Link to="/about/classes" className="navbar-item">
                    Classes
                  </Link>
                  <Link to="/about/what-to-bring" className="navbar-item">
                    What to Bring
                  </Link>
                  <Link to="/about/contact" className="navbar-item">
                    Contact
                  </Link>
                </div>
              </div>
              <Link className="navbar-item" to="/schedule">
                Schedule
              </Link>
              <Link className="navbar-item" to="/vault">
                EVOLVE Vault
              </Link>
              <Link className="navbar-item" to="/shop">
                Shop
              </Link>
              <Link className="navbar-item" to="/sign-up">
                Sign Up
              </Link>
            </div>
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
